.logo {
    background-image: url("../public/images/logo.png");
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
}

.image-fit-container img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
}

.row-reverse-container > div {
    flex-direction: row-reverse !important;
}

a.link > div {
    transition-duration: 0.4s;
    transition-property: background-color, color;
    transition-timing-function: ease;
}
a.link * {
    text-decoration: underline !important;
    text-decoration-thickness: from-font !important;
    text-underline-offset: 0.125rem !important;
}

a:focus {
    box-shadow: 0 0 2pt 2pt lightblue;
}
a:focus-visible {
    box-shadow: 0 0 2pt 2pt lightblue;
}

a.textlink span {
    overflow-wrap: anywhere;
}

.no-hover-card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
}

.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.hover-card {
    box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
}

.hover-card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.kurstag {
    /* flex-grow: 0 !important; */
    /* flex-basis: 0% !important; */
}

/* CSS loader from loading.io */

.lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-ripple div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1.75s cubic-bezier(0, 0.1, 0.4, 1) infinite;
}
.lds-ripple div:nth-child(2) {
    animation-delay: 1s;
}
.lds-ripple div {
    animation-delay: 1.5s;
}
@keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}

@media print {
    .no-print,
    .no-print * {
        display: none !important;
    }
}
